function ShopNow(){
    return (
        <a href="https://payhip.com/ericdimarzio" target="_blank" rel="noreferrer">
            <button className="shopnow-button">Shop Now</button>
        </a>

    )
}

export default ShopNow
