function Footer(){
    return (
        <div className="footer section">
            <p>© Eric DiMarzio</p>
            <div className="legal">

            <div>
            Want a website like this? Contact Eric for your own personal site.
            </div>
            <div> 
               
                 Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik"> Freepik </a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com'</a></div>
                
        </div>
            </div>
    )
}

export default Footer

